import React, { useRef } from "react";
import { SlSocialInstagram } from "react-icons/sl";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AiFillLinkedin } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { PiInstagramLogoFill } from "react-icons/pi";
import { IoLogoLinkedin } from "react-icons/io";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Footer = () => {
  return (
    <>
      <div className="wefre ">
        <div
          className="udhciwe"
          style={{
            backgroundColor: "#808185",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="ejfie pb-4" style={{ paddingTop: "25px" }}>
              <div className="row">
                <div className="col-md-3">
                  <h5
                    className="text-uppercase mb-4 font-weight-bold"
                    style={{ color: "white", fontFamily: "Carnas Light" }}
                  >
                    Contact information
                  </h5>
                  <hr
                    style={{
                      border: "1px solid white",
                      color: "white",
                      width: "80%",
                    }}
                  />
                  {/* <img
                  src="logo.jpg"
                  alt=""
                  style={{
                    width: "30%",
                    height: "7rem",
                    borderRadius: "50%",
                  }}
                />
                <br /> */}
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <MdLocationPin style={{ fontSize: "23px" }} />
                      </span>
                      <span style={{ fontFamily: "inherit" }}>
                        Survey No. 23, Masthappa Layout, Near Yashas Vidya
                        Kendra, Somashettihalli, Chikkabanavara(Post), Bangalore
                        - 560090.
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-3">
                      <span>
                        <GrMail />
                      </span>
                      <span style={{ fontFamily: "inherit" }}>
                        {" "}
                        info@uisolutions.co.in
                        </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-3">
                      <span>
                        <BiSolidPhoneCall />
                      </span>
                      <span style={{ fontFamily: "inherit" }}>
                      +91-9900260845 / 9945760845
                        </span>
                    </p>
                  </p>
                </div>
                <div className="col-md-2">
                  <h5
                    className="text-uppercase mb-4 font-weight-bold"
                    style={{ color: "white", fontFamily: "Carnas Light" }}
                  >
                    QUICK LINKS
                  </h5>
                  <hr
                    style={{
                      border: "1px solid white",
                      color: "white",
                      width: "70%",
                    }}
                  />
                    <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <MdKeyboardDoubleArrowRight
                          style={{ fontSize: "23px" }}
                        />
                      </span>
                      <span>
                        <a
                          href="/"
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Home
                        </a>
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <MdKeyboardDoubleArrowRight
                          style={{ fontSize: "23px" }}
                        />
                      </span>
                      <span>
                        <a
                          href="/about"
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          About Us
                        </a>
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <MdKeyboardDoubleArrowRight
                          style={{ fontSize: "23px" }}
                        />
                      </span>
                      <span>
                        <a
                          href="/product"
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Products & Services
                        </a>
                      </span>
                    </p>
                  </p>
                 
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <MdKeyboardDoubleArrowRight
                          style={{ fontSize: "23px" }}
                        />
                      </span>
                      <span>
                        <a
                          href="/contact"
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Contact Us
                        </a>
                      </span>
                    </p>
                  </p>
                </div>
                <div className="col-md-2">
                  <h5
                    className="text-uppercase mb-4 font-weight-bold"
                    style={{ color: "white", fontFamily: "Carnas Light" }}
                  >
                    SOCIAL MEDIAS
                  </h5>
                  <hr
                    style={{
                      border: "1px solid white",
                      color: "white",
                      width: "80%",
                    }}
                  />
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <FaFacebookSquare style={{ fontSize: "23px" }} />
                      </span>
                      <span>
                        <a
                          href=""
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Facebook
                        </a>
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <FaTwitter style={{ fontSize: "23px" }} />
                      </span>
                      <span>
                        <a
                          href=""
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Twitter
                        </a>
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <PiInstagramLogoFill style={{ fontSize: "23px" }} />
                      </span>
                      <span>
                        <a
                          href=""
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Instagram
                        </a>
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <IoLogoLinkedin style={{ fontSize: "23px" }} />
                      </span>
                      <span>
                        <a
                          href=""
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Linked in
                        </a>
                      </span>
                    </p>
                  </p>
                </div>
              
                <div className="col-md-5">
                  <div className="container">
                    <div className="row" style={{ padding: "6px" }}>
                      <div className="col-md-12 text-center">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15544.75833102689!2d77.50175674150438!3d13.087168296320192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22fbbda18765%3A0x667331bfa3733569!2sYashas%20Vidya%20Kendra!5e0!3m2!1sen!2sin!4v1707733938744!5m2!1sen!2sin"
                          style={{width:"100%",
                          height:"250px"}}
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ color: "whitesmoke" }} />
           
            <div
              className="aefe"
              style={{ display: "flex", justifyContent: "space-between" }}
            ></div>
            <div style={{ backgroundColor: "red" }}></div>
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <p style={{ color: "whitesmoke", fontFamily: "inherit" }}>
                    Parnets Digital Advertisment Pvt Ltd All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
