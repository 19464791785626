import React, { Component, useEffect, useRef } from "react";
import Carousel from "react-bootstrap/Carousel";
import "../Components/Styles/home.css";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Aos from "aos";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

const Home = () => {
  const form = useRef();
  const sendEmail = (e) => {
   

    e.preventDefault();
    emailjs
      .sendForm(
        "service_3w3g9h7",
        "template_oi0qp23",
        form.current,
        "MUIWT1wj9Eqv9fJC0"
      )
      .then(
        (result) => {
          console.log("Inquiry sent successfully:", result.text);
          window.location.assign("/");
        },
        (error) => {
          console.error("Error sending inquiry:", error.text);
        }
      );
  };
  const navigate= useNavigate();
  useEffect(()=>{
    Aos.init();
    window.scroll(0,0);
  })
  return (
    <div>
      <Carousel fade autoPlay>
    
        <Carousel.Item>
          {/* <ExampleCarouselImage text="First slide" /> */}
          <img
            src="carousal-img-2.jpg"
            alt="Second slide"
            style={{ width: "100%", height: "500px" }}
          />
          <Carousel.Caption>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2
                className="title"
                style={{
                  fontSize: "53px",
                  fontFamily: "Roboto, sans-serif",
                  fontStyle: "bold",
                  color: "white",
                  backgroundColor: "#808185",
                }}
              >
                Safe Guarding your Valuables
              </h2>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {/* <ExampleCarouselImage text="First slide" /> */}
          <img
            src="carousal-img-3.jpg"
            alt="Third slide"
            style={{ width: "100%", height: "500px" }}
          />
          <Carousel.Caption>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2
                className="title"
                style={{
                  fontSize: "53px",
                  fontFamily: "Roboto, sans-serif",
                  fontStyle: "bold",
                  color: "white",
                  backgroundColor: "#808185",
                }}
              >
                Packaging is Protecting
              </h2>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {/* <ExampleCarouselImage text="First slide" /> */}
          <img
            src="carousal-img-4.png"
            alt="Fourth slide"
            style={{ width: "100%", height: "500px" }}
          />
          <Carousel.Caption>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2
                className="title"
                style={{
                  fontSize: "53px",
                  fontFamily: "Roboto, sans-serif",
                  fontStyle: "bold",
                  color: "white",
                  backgroundColor: "#808185",
                }}
              >
                One Step Solutions
              </h2>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {/* <ExampleCarouselImage text="First slide" /> */}
          <img
            src="carousal-img-5.jpg"
            alt="Fifth slide"
            style={{ width: "100%", height: "500px" }}
          />
          <Carousel.Caption>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2
                className="title"
                style={{
                  fontSize: "53px",
                  fontFamily: "Roboto, sans-serif",
                  fontStyle: "bold",
                  color: "white",
                  backgroundColor: "#808185",
                }}
              >
                Packaging is Branding Self - Be A Leader
              </h2>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className="container">
        <div className="row mt-5">
          <div className="row text-center mb-2" data-aos="fade-up" data-aos-duration="2000">
            <h2 className="text-typer-animation">
              WELCOME TO UNIQUE INDUSTRIAL SOLUTIONS
            </h2>
          </div>
          <div data-aos="fade-up" data-aos-duration="2000"   >
            

            <div className="about-display">
              <div className="about-content mb-2">
              <div className="first-box">
                <p style={{textAlign:"justify"}}>
                  Unique Industrial Solutions (UIS) is a undisputed leader in
                  Packaging Solutions be it is domestic or export with industry
                  experience of 10+years in Packaging Industry.
                  <br />
                  <br />
                  UIS ensure that all outgoing goods are certified & accompanied
                  by our own reports and certificates of quality assurance when
                  required. Customers are assured of Traceability,
                  Accountability & Reliability. Moving forward UIS will continue
                  to enhance the quality of its products and services. We are
                  committed to deliver cost-effective solutions to our
                  customers.
                  <br />
                  <br />
                  UIS provide a one stop-solution & services to the customers.
                  UIS is dedicated to few policies like quality of product,
                  success of our customer, customer satisfaction. With our
                  steady growth and commitment over the years, we pledge to
                  serve our customers in any industry with excellent services
                  and superior products.
                </p>
                <a href="/about">Know More...</a>
              </div>
              </div>
              <div className="about-content">
              <div>
                <img
                  className="homeimg"
                  src="home-img-1.jpg"
                  alt=""
                  style={{
                    border: "3px solid #010161",
                  }}
                />
              </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#808185", marginTop: "3rem" }}>
        <div className="container">
          <div className="row" data-aos="fade-up" data-aos-duration="2000">
            <div
              className="col-md-12 mt-1 mb-4 text-center"
              style={{ paddingTop: "2rem" }}
            >
              <h2 className="text-typer-animation">PRODUCT RANGE</h2>
            </div>
          </div>
          <div className="row" style={{ paddingBottom: "2rem" }}>
            <div className="col-md-3 d-flex justify-content-center" data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "17rem", cursor: "pointer" }}
                className="col-lg-3 mb-4 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="product-1.jpg"
                  style={{ height: "193px" }}
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Packaging Solutions
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3 d-flex justify-content-center" data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "17rem", cursor: "pointer" }}
                className="col-lg-3 mb-4 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="product-2.jpg"
                  style={{ height: "193px" }}
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Packaging Box & Pallets
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3 d-flex justify-content-center" data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "17rem", cursor: "pointer" }}
                className="col-lg-3 mb-4 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="product-3.jpg"
                  style={{ height: "193px" }}
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Packaging Foams
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3 d-flex justify-content-center" data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "17rem", cursor: "pointer" }}
                className="col-lg-3 mb-4 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="product-4.jpg"
                  style={{ height: "193px" }}
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Packaging Covers
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3 d-flex justify-content-center"  data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "17rem", cursor: "pointer" }}
                className="col-lg-3 mb-4 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="product-5.png"
                  style={{ height: "193px" }}
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Wooden Box
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3 d-flex justify-content-center"  data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "17rem", cursor: "pointer" }}
                className="col-lg-3 mb-4 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="product-6.jpeg"
                  style={{ height: "193px" }}
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Special Combination Box
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 d-flex justify-content-center"  data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "17rem", cursor: "pointer" }}
                className="col-lg-3 mb-4 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="service-1.jpg"
                  style={{ height: "193px" }}
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Packaging Solutions
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3 d-flex justify-content-center"  data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "17rem", cursor: "pointer" }}
                className="col-lg-3 mb-4 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="service-1.jpg"
                  style={{ height: "193px" }}
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Packaging Solutions
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3 d-flex justify-content-center"  data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "17rem", cursor: "pointer" }}
                className="col-lg-3 mb-4 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="service-3.jpg"
                  style={{ height: "193px" }}
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Packaging Solutions
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-3 d-flex justify-content-center"  data-aos="zoom-in" data-aos-duration="3000">
              <Card
                style={{ width: "17rem", cursor: "pointer" }}
                className="col-lg-3 mb-4 p-0"
              >
                <Card.Img
                  className="cardImage"
                  variant="top"
                  src="service-4.jpg"
                  style={{ height: "193px" }}
                />
                <Card.Body>
                  <Card.Title style={{ color: "#010161" }}>
                    Packaging Solutions
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row"  data-aos="fade-up" data-aos-duration="3000">
            <div
              className="col-md-12 mt-2 text-center"
              style={{ marginBottom: "2rem" }}
            >
              <button className="btn btn-primary viewAllProducts" onClick={()=>navigate("/product")}>
                View All products
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container counts_bg"
    
      >
        <div className="bs-slider"></div>
        <div className="row text-center">
          <div
            style={{ backgroundColor: "#1487c675" }}
            className="col-md-6 "
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <h1 className="mt-4">Take a Step Ahead &</h1>
            <h5 style={{ color: "black" }}>
              Tell Us What You Are Looking For?
            </h5>
            <div className="row mt-5 p-3">
              <Form ref={form} onSubmit={sendEmail}>
                <Row>
                  <Col>
                    <Form.Control type="text" placeholder="Full name" name="user_name" required/>
                  </Col>
                  
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Control type="email" placeholder="Email" name="user_email" required/>
                  </Col>
                  <Col>
                    <Form.Control type="number" maxLength={10} placeholder="Phone number" name="user_phone" required/>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Control type="text" placeholder="Product / service name" name="user_service" required/>
                  </Col>
                </Row>
                <br />
                <Form.Control
                  as="textarea"
                  placeholder="Your message here..."
                  style={{ height: "100px" }}
                  name="user_message"
                />

                <br />
                <div className="d-flex align-items-center pb-1 justify-content-center">
                  <div className="p-1">
                    <Button
                    type="submit"
                    value="Send"
                      className="btn-danger p-3 viewAllProducts"
                      style={{ backgroundColor: "#FE5E00" }}
                    >
                      Submit Enquiry
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
