import React, { useEffect, useRef } from "react";
import { HiLocationMarker } from "react-icons/hi";
import "../Components/Styles/contact.css";
import { FaPhone } from "react-icons/fa6";
import { IoMailOpenSharp } from "react-icons/io5";
import Aos from "aos";
import emailjs from "@emailjs/browser";
import { Button, Col, Form, Row } from "react-bootstrap";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_3w3g9h7",
        "template_oi0qp23",
        form.current,
        "MUIWT1wj9Eqv9fJC0"
      )
      .then(
        (result) => {
          alert("Inquiry sent successfully:", result.text);
          window.location.assign("/contact");
        },
        (error) => {
          alert("Error sending inquiry:", error.text);
        }
      );
  };


 
  console.log("data", form);
  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });
  return (
    <div>
      <div className="contcat-banner-logo-section">
        <div className="contact-display">
          <h2 className="contact-main-title">GET IN TOUCH WITH US</h2>
        </div>
      </div>

      <div className="contact-cards-container">
        <div className="contact-cards">
          <div
            className="card"
            style={{
              width: "100%",
              height: "160px",
              boxShadow: "1px -1px 6px 0px #808185",
            }}
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="card-body">
              <div className="address-title-section">
                <HiLocationMarker
                  style={{ width: "20px", height: "20px", color: "#1487C6" }}
                />
                <h5 className="card-title">Address</h5>
              </div>
              <p style={{ padding: "0px 10px" }} className="card-text">
                Survey No. 23, Masthappa Layout, Near Yashas Vidya Kendra,
                Somashettihalli, Chikkabanavara(Post), Bangalore - 560090.
              </p>
            </div>
          </div>
          <div
            className="card"
            style={{
              width: "100%",
              height: "160px",
              boxShadow: "1px -1px 6px 0px #808185",
            }}
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="card-body">
              <div className="address-title-section">
                <FaPhone
                  style={{ width: "22px", height: "20px", color: "#1487C6" }}
                />
                <h5 className="card-title">Phone Number</h5>
              </div>
              <p style={{ padding: "0px 10px" }} className="card-text">
              +91-9900260845 <br /> <br/>
              +91-9945760845
              </p>
            </div>
          </div>
          <div
            className="card"
            style={{
              width: "100%",
              height: "160px",
              boxShadow: "1px -1px 6px 0px #808185",
            }}
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="card-body">
              <div className="address-title-section">
                <IoMailOpenSharp
                  style={{ width: "20px", height: "20px", color: "#1487C6" }}
                />
                <h5 className="card-title">E-Mail</h5>
              </div>
              <p style={{ padding: "0px 10px" }} className="card-text">          
                <p> info@uisolutions.co.in </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-2 mt-2">
        <div className="row contact-form-section">
          <div className="col-md-6" data-aos="fade-up" data-aos-duration="3000">
            <div className="form-container">
              <Form
                style={{ padding: "20px", background: "#808185" }}
                ref={form}
                onSubmit={sendEmail} // Attach the sendEmail function to the onSubmit event
              >
                <h4>Enquire Now</h4>
                <input
                  type="text"
                  id="user_name"
                  name="user_name"
                  placeholder="Enter Your name"
                  required
                  style={{
                    margin: "6px 0 16px 0",
                    padding: "10px",
                  }}
                />

                <input
                  type="email"
                  id="user_email"
                  name="user_email"
                  placeholder="Enter Your Email"
                  required
                  style={{
                    margin: "6px 0 16px 0",
                    padding: "10px",
                  }}
                />

                <input
                  type="number"
                  maxLength={10}
                 pattern="\d{10}"
                  id="user_phone"
                  name="user_phone"
                  placeholder="Enter Your Number"
                  required
                  style={{
                    margin: "6px 0 16px 0",
                    padding: "10px",
                  }}
                />

                <textarea
                  id="user_message"
                  name="user_message"
                  placeholder="Enter Your Message"
                  required
                  style={{
                    height: "150px",
                    margin: "6px 0 16px 0",
                    padding: "10px",
                  }}
                ></textarea>
                <button className="send-button" type="submit" value="Send">
                  Send Message
                </button>
              </Form>
            </div>
          </div>
          <div
            className="col-md-6 mt-2"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15544.75833102689!2d77.50175674150438!3d13.087168296320192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22fbbda18765%3A0x667331bfa3733569!2sYashas%20Vidya%20Kendra!5e0!3m2!1sen!2sin!4v1707733938744!5m2!1sen!2sin"
              style={{ width: "100%", height: "85%" }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="tell-us-display">
        <div className="container">
          <div className="row">
            <div
              className="col-md-4 mb-2"
              data-aos="zoom-in"
              data-aos-duration="3000"
            >
              <img
                src="enquire-img.jpg"
                alt=""
                style={{ width: "100%", height: "300px" }}
              />
            </div>
            <div
              className="col-md-8"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <h2>Tell Us What You Are Looking For?</h2>
              <Form action="" ref={form} onSubmit={sendEmail}>
                <div className="inputs-row">
                  <div className="col-6 mb-2">
                    <input
                      className="text"
                      type="text"
                      name="user_name"
                      maxLength={10}
                      placeholder="Enter Your Name"
                      required
                    />
                  </div>

                  <div className="col-6 mb-2">
                    <input
                      className="text"
                      type="text"
                      name="user_phone"
                      maxLength={10}
                      placeholder="Enter Your Mobile"
                      required
                    />
                  </div>
                </div>

                <textarea
                  id="subject"
                  name="user_message"
                  placeholder="Enter Your message here"
                  style={{
                    height: "111px",
                    margin: "6px 0 16px 0",
                    padding: "10px",
                  }}
                  required
                ></textarea>
                <div className="row">
                  <div
                    className="col-md-12 mt-2"
                    style={{ marginBottom: "2rem" }}
                  >
                    <button
                      type="submit"
                      value="Send"
                      className="btn btn-primary viewAllProducts"
                      style={{ width: "5rem" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
