import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import AboutUs from "./Components/AboutUs";
import Product_Services from "./Components/Product_Services";
import NavBar1 from "./Components/NavBar1";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <NavBar1 />
                <Home />
                <Footer />
              </>
            }
          />
           <Route
            path="/about"
            element={
              <>
                <Header />
                {/* <Navbar /> */}
                <NavBar1 />
                <AboutUs />
                <Footer />
              </>
            }
          />
           <Route
            path="/product"
            element={
              <>
                <Header />
               {/* <Navbar /> */}
               <NavBar1 />
                <Product_Services />
                <Footer />
              </>
            }
          />
           <Route
            path="/contact"
            element={
              <>
                <Header />
               {/* <Navbar /> */}
               <NavBar1 />
                <Contact />
                <Footer />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
