import React from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavDropdown from "react-bootstrap/NavDropdown";
import { TiArrowSortedDown } from "react-icons/ti";
import "../Components/Styles/navbar1.css";
import { Navigate, useNavigate } from "react-router-dom";

const NavBar1 = () => {
  const navigate = useNavigate();
  const userLogin = JSON.parse(sessionStorage.getItem("user"));
  const userLogout = () => {
    sessionStorage.removeItem("user");
    window.location.assign("/");
  };
  return (
    <>
      <div
        className="headers"
        style={{
          position: "sticky",
          top: "0px",
          zIndex: "999",
          backgroundColor: "white",
        }}
      >
        <div className="sdf">
        <Nav
          className="justify-content-start flex-grow-1 pe-3"
          style={{ alignItems: "center" }}
        >
          <div>
            <a href="/">
            <img src="logo.jpg" alt="logo" className="navbar-logo-mbl" />
            </a>
          </div>
        </Nav>

        {["xl"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            style={{ padding: "0px", background: "white" }}
          >
            <Container fluid style={{ padding: "5px" }}>
              {/* <Navbar.Brand href="#">
                <img
                  src="../images/pc.png"
                  alt="logo"
                  style={{ width: "120px", height: "86px" }}
                />
              </Navbar.Brand> */}
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Offcanvas
                  </Offcanvas.Title> */}
                </Offcanvas.Header>
                <Offcanvas.Body
                  style={{ padding: "0px", alignItems: "center" }}
                >
                  <Nav
                    className="justify-content-start flex-grow-1 pe-3"
                    style={{ alignItems: "center" }}
                  >
                    <div>
                      <a href="/">
                      <img src="logo.jpg" alt="logo" className="navbar-logo" />
                      </a>
                    </div>
                  </Nav>
                  <div className="navbar-right-content">
                    <Nav
                      className="justify-content-start flex-grow-1 pe-3"
                      style={{ alignItems: "center", alignItems: "center" }}
                    >
                      <Nav.Link href="/" className="tail-text">
                        HOME
                      </Nav.Link>
                      {/* </Nav.Link> */}
                      <Nav.Link href="/about" className="tail-text">
                        ABOUT US
                      </Nav.Link>
                      {/* <Nav.Link href="/services" className="tail-text">
                        SERVICES
                      </Nav.Link> */}
                      <NavDropdown
                        onClick={() => navigate("/product")}
                        className="dropdown"
                        title="PRODUCTS & SERVICES"
                        id="basic-nav-dropdown"
                      >
                        <div className="dropdown nav-link">
                          <div>
                            <NavDropdown.Item href="#Pacaking Box & Pallets">
                              Pacaking Box & Pallets
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#Pacaking Foams">
                              Pacaking Foams
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#Pacaking Covers">
                              Pacaking Covers
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#Packing Solutions">
                              Pacaking Solution
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#Printing Solutions">
                              Printing Solutions
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#Tape Solutions">
                              Tape Solutions
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#Safety Items">
                              Safety Items
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#Industrial Consumables Foams">
                              Industrial Consumables Foams
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#Industrial Spares & Machinary Sapares">
                              Industrial Spares & Machinary Sapares
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#Services">
                              Services
                            </NavDropdown.Item>
                          </div>
                        </div>
                      </NavDropdown>
                      <Nav.Link href="/contact" className="tail-text">
                        CONTACT US
                      </Nav.Link>
                    </Nav>
                  </div>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
        </div>
      </div>
    </>
  );
};

export default NavBar1;
