import React from "react";
import { Container } from "react-bootstrap";
import { GrMail } from "react-icons/gr";
import { BiSolidPhoneCall } from "react-icons/bi";
import { BiSolidTime } from "react-icons/bi";
import { FaRegHandshake } from "react-icons/fa6";
import "./Styles/navbar.css"

const Header = () => {
  return (
    <div
    className="Header"
      style={{
        position: "sticky",
        top: "0",
        zIndex: "99999",
      }}
    >
      <Container
        fluid
        className="header"
        style={{ background: "#1487C6", height: "32px" }}
      >
        <Container fluid className="top-navbar pt-1 header1">
          <div className="d-flex gap-3">
            <div>
              <span>
                <GrMail
                  style={{
                    fontSize: "19px",
                    color: "#233072",
                    marginRight: "5px",
                  }}
                />
              </span>
              <span style={{ fontSize: "15px", color: "white" }}>
              info@uisolutions.co.in
              </span>
            </div>
            |
            <div>
              <span>
                <BiSolidPhoneCall
                  style={{
                    fontSize: "19px",
                    color: "#233072",
                    marginRight: "4px",
                  }}
                />
              </span>
              <span style={{ fontSize: "15px", color: "white" }}>
                +91-9900260845 / 9945760845
              </span>
            </div>
            |
            <div className="d-flex gap-2 text-end">
              <span>
                <BiSolidTime
                  style={{
                    fontSize: "19px",
                    color: "#233072",
                    marginRight: "3px",
                  }}
                />
              </span>
              <span style={{ fontSize: "15px", color: "white" }}>
                9:00AM-8:00PM
              </span>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default Header;
