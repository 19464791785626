import React, { useEffect, useRef, useState } from "react";
import "../Components/Styles/product_services.css";
import { Button, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaFolderOpen } from "react-icons/fa";
import { MdPerson } from "react-icons/md";
import { FaPhoneFlip, FaRegHandPointRight } from "react-icons/fa6";
import { AiTwotoneMail } from "react-icons/ai";
import { SiMinutemailer } from "react-icons/si";
import Aos from "aos";
import emailjs from "@emailjs/browser";

const Product_Services = () => {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const form = useRef();
  const sendEmail = (e) => {
   

    e.preventDefault();
    emailjs
      .sendForm(
        "service_3w3g9h7",
        "template_oi0qp23",
        form.current,
        "MUIWT1wj9Eqv9fJC0"
      )
      .then(
        (result) => {
          console.log("Inquiry sent successfully:", result.text);
          window.location.assign("/product");
        },
        (error) => {
          console.error("Error sending inquiry:", error.text);
        }
      );
  };



  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });
  return (
    <div style={{ backgroundColor: "#E0E0E0" }}>
      <div className="contcat-banner-logo-section" style={{ height: "100px" }}>
        <div className="contact-display">
          <h2 className="contact-main-title">UIS Products & Services</h2>
        </div>
      </div>

      <div className="container">
        <div className="row mt-3">
          <div
            className="col-md-7 mt-3 about"
            style={{textAlign:"justify"}}
            
          >
            <div className="row">
              <div
                className="col-md-11 "
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <p 
                className="about-desc"
                >
                  <b>1. Packing boxes are divided in to 2 Types:</b>
                  <br />
                  <ul>
                    <li>Unit Carton boxes</li>
                    <li>Master Carton Boxes</li>
                  </ul>
                  <b>2. Packing boxes can be made as per customer drawings</b>
                  <br />
                  <ul>
                    <li>
                      Available in 2-ply, 3-ply, 5-ply, 7-ply with different
                      flute shapes & sizes.
                    </li>
                    <li>
                      Strength & durability can be obtained as per the
                      requirement Engineered drawing conversions to sample &
                      samples to approved drawings.
                    </li>
                  </ul>
                  <b>3. Master Carton Boxes as per customer requirement</b> <br />
                  <b>4. Pallets are of different types:</b>
                  <ul>
                    <li>Wooden pallets</li>
                    <li>Corrugated pallets</li>
                    <li>Heat treated pallets</li>
                    <li>Plywood pallets (Export use)</li>
                    <li>Honeycomb Pallets </li>
                  </ul>
                </p>
              </div>
              <div className="col-md-5 text-end">
                <img
                  className="productimg"
                  src="products-1.jpg"
                  alt=""
                  style={{
                    width: "85%",
                    height: "500px",
                    position: "absolute",
                    top: "3rem",
                    left: "600px",
                    border: "3px solid #1487C6",
                  }}
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-3" >
          <div
            className="col-md-7 mt-3 about"
            style={{
              backgroundColor: "#1487C6",
            }}
          >
            <div className="row" id="Packing Solutions">
              <div
                className="col-md-11 "
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <p
                  className="about-desc"
                >
                  <b>We are also into Packing Consumables like:</b>
                  <br />
                  <br />
                  <ul>
                    <li>
                      {" "}
                      <b>PACKING TAPES</b> - BOPP Tapes, CLEAR Tapes, PRINTED
                      Tapes
                    </li>
                    <li>
                      <b>TAPES SOLUTIONS</b> - Filament Tapes, Masking Tapes,
                      Kapton Tape, Double Sided Tape, ESD Conductive Grid Tape
                      Etc.,
                    </li>
                    <li>
                      <b>PRINTING SOLUTIONS</b> - Labels/Ribbons - Thermal, Heat
                      resistant etc.,
                    </li>
                    <li>
                      <b>SAFETY SOLUTIONS:</b> - PPE’s, Safety solutions, any
                      type Gloves
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-md-5 text-end">
                <img
                  className="productimg"
                  src="services-2.jpg"
                  alt=""
                  style={{
                    width: "68%",
                    height: "425px",
                    position: "absolute",
                    top: "0rem",
                    left: "600px",
                    border: "3px solid #1487C6",
                  }}
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="row products"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="col-md-3"  id="Pacaking Box & Pallets">
                  <img
                    src="products-1.jpg"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9">
                  <h3 style={{ color: "#1487C6" }}>PACKAGING SOLUTIONS</h3>
                  <p className="about-des">
                    Packaging is the technology of enclosing or protecting
                    products for distribution, storage, sale, and use. Packaging
                    also refers to the process of designing, evaluating, and
                    producing packages. Packaging can be described as a
                    coordinated system of preparing goods for transport,
                    warehousing, logistics, sale, and end use. Packaging
                    contains, protects, preserves, transports, informs, and
                    sells.
                    <br /> This guide is for small retailers looking to find the
                    best way to package their products. We’ll go over the basic
                    envelope/boxing options and show you a detailed comparison
                    of packing materials, including bubble wrap, foam and
                    peanuts.
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
              </div>

              <div
                id="Pacaking Box & Pallets"
                className="row products"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="col-md-3">
                  <img
                    src="products-2.jpg"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9" style={{textAlign:"justify"}}>
                  <h3 style={{ color: "#1487C6" }}>PACKING BOX & PALLETS</h3>
                  <br />
                  <h4 style={{ color: "#1487C6" }}>Packing Boxes</h4>
                  <p style={{textAlign:"justify"}}>
                    <ul style={{padding:"0px", fontSize:"15px", textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        <b> 1. Packing boxes are divided in to 2 types:</b>
                      </li> 

                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Unit Carton boxes
                      </li>
                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Master Carton Boxes
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          2.Packing boxes can be made as per customer drawings.
                        </b>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          3. Available in 2-ply, 3-ply, 5-ply, 7-ply with
                          different flute shapes & sizes.
                        </b>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          4. Strength & durability can be obtained as per the
                          requirement.
                        </b>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          5. Engineered drawing conversions to sample & samples
                          to approved drawings.
                        </b>
                      </li>
                    </ul>
                  </p>

                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
                <hr className="mt-3 mb-1" />
                <div
                  className="col-md-6 "
                  style={{ borderRight: "1px solid #E3E3E4" }}
                >
                  <div className="text-center">
                    <img
                    className="pimg"
                      src="products-3.png "
                      alt=""
                     
                    />
                  </div>
                  <br />
                  <h4 style={{ color: "#1487C6" }}>
                    Corrugated Master Carton Boxes
                  </h4>
                  <p>
                  <ul style={{padding:"0px", fontSize:"15px", textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          <FaRegHandPointRight
                            style={{ color: "#1487C6" }}
                          />{" "}
                          Master Cartons are used to pack the unit cartons for
                          export shipments.
                        </b>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          <FaRegHandPointRight
                            style={{ color: "#1487C6" }}
                          />{" "}
                          Available in 2-ply, 3-ply, 5-ply, 7-ply with different
                          flute shapes & sizes
                        </b>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          <FaRegHandPointRight
                            style={{ color: "#1487C6" }}
                          />{" "}
                          Strength & durability can be obtained as per the
                          requirement
                        </b>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          <FaRegHandPointRight
                            style={{ color: "#1487C6" }}
                          />{" "}
                          Engineered drawing conversions to sample & samples to
                          approved drawings
                        </b>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          <FaRegHandPointRight
                            style={{ color: "#1487C6" }}
                          />{" "}
                          Engineered drawing conversions to sample & samples to
                          approved drawings.
                        </b>
                      </li>
                    </ul>
                  </p>

                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button
                        className="btn btn-primary viewAllProducts"
                        onClick={handleShow1}
                      >
                        Ask For Price
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="text-center">
                    <img
                    className="pimg"
                      src="products-4.jpg "
                      alt=""
                    />
                  </div>
                  <br />
                  <h4 style={{ color: "#1487C6" }}>Pallets</h4>
                  <p>
                  <ul style={{padding:"0px", fontSize:"15px",textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        <b> 1. Pallets are of different types:</b>
                      </li>

                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Unit Wooden pallets
                      </li>
                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Corrugated pallets
                      </li>
                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Heat treated pallets
                      </li>
                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Plywood pallets (Export use)
                      </li>
                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Honeycomb Pallets
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b> 2. Pallets are also differentiated as below:</b>
                      </li>
                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} /> Two
                        way entry
                      </li>
                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Four way entry
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          3. Customized pallets are also available based on
                          customer requirements in plastic.
                        </b>
                      </li>
                    </ul>
                  </p>

                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button
                        className="btn btn-primary viewAllProducts"
                        onClick={handleShow1}
                      >
                        Ask For Price
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="mt-3 mb-1" />
                <div className="col-md-3">
                  <img
                    src="products-5.jpg"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9">
                  <h3 style={{ color: "#1487C6" }}>PACKING BOX & PALLETS</h3>
                  <br />
                  <h4 style={{ color: "#1487C6" }}>Packing Boxes</h4>
                  <p>
                  <ul style={{padding:"0px", fontSize:"15px",textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          1. Paper Angle Boards are ideal for stacking goods and
                          packaging support.
                        </b>
                      </li>

                      <li style={{ listStyle: "none" }}>
                        <b> 2.Available mainly in L-shaped and U-shaped.</b>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          3. Available size from 25mm to 100mm thickness 3mm &
                          6mm
                        </b>
                      </li>
                    </ul>
                  </p>

                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
              </div>

              <div
                className="row products"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="col-md-3">
                  <img
                    src="product-5.png"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9 xfgfd">
                  <h3 style={{ color: "#1487C6" }}>Wooden Box</h3>
                  <p>
                    <FaRegHandPointRight style={{ color: "#1487C6" }} />
                    Wooden Box export worthy boxes as per customer design.
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
              </div>

              <div id="Packing Foams"
                className="row products"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="col-md-3">
                  <img
                    src="products-6.jpeg"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9 xfgfd">
                  <h3 style={{ color: "#1487C6" }}>Special Combination Box</h3>
                  <p>
                    <FaRegHandPointRight style={{ color: "#1487C6" }} /> Cost
                    effective combination of Plywood and Corrugated box
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
              </div>

              <div
                id="Pacaking Covers"
                className="row products"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="col-md-3">
                  <img
                    src="products-8.jpg"
                    alt=""
                    style={{ width: "100%", height: "322px" }}
                  />
                </div>
                <div className="col-md-9 mt-2">
                  <h3 style={{ color: "#1487C6" }}>PACKING FOAMS</h3>
                  <br />
                  <h4 style={{ color: "#1487C6" }}>XL-PE Foams</h4>
                  <p style={{ textAlign:"justify" }}>
                    Cross Linked PE is smooth and flat surfaced. It has
                    excellent mechanical properties. It is extremely resilient,
                    with high load bearing capacity. It has good dimensional
                    stability. It is easily thermoformable. It is non-corrosive,
                    non-abrasive .It has low water absorption. It has very good
                    high temperature tolerance. In medical applications it is
                    used because of its very fine cell and smooth feel. Standard
                    Colors are white (sometimes referred to as natural) and
                    black(also referred to as grey). Other colors are available
                    on orders at an affordable premium. Cross Linked PE is
                    available in a large number of grades with varying density,
                    shore hardness and other mechanical properties. Sheet size
                    is available in various thickness ranging from 2mm to 100mm.
                    Standard size of the sheet is 1000mm x 2000mm.
                  </p>

                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
                <hr className="mt-3 mb-1" />
                <div
                  className="col-md-6 "
                  style={{ borderRight: "1px solid #E3E3E4" }}
                >
                  <div className="text-center">
                    <img
                    className="pimg"
                      src="products-7.jpg "
                      alt=""
                    />
                  </div>
                  <br />
                  <h4 style={{ color: "#1487C6" }}>Packing PE Foams</h4>
                  <p style={{ textAlign: "justify" }}>
                    It is used for prosthetic and orthotic soft padding
                    application available in various colours, thickness,
                    hardness usual size 1 meter x 2 meter minimum order quantity
                    1 sheet.
                  </p>

                  <div className="row ">
                    <div className="col-md-12 text-center">
                      <button
                        className="btn btn-primary viewAllProducts"
                        onClick={handleShow1}
                      >
                        Ask For Price
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="text-center">
                    <img
                       className="pimg"
                      src="products-4.jpg "
                      alt=""
                    />
                  </div>
                  <br />
                  <h4 style={{ color: "#1487C6" }}>Packing PU Foams</h4>
                  <p>
                  <ul style={{padding:"0px", fontSize:"15px",textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        <b> 1. Foam is available in different form as below:</b>
                      </li>

                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Unit Packing foam with density varying from 1lb/ft3 to
                        1.8lb/ft3.
                      </li>
                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Anti-Static foam in Black & Pink colour.
                      </li>
                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Heat treated pallets
                      </li>
                      <li>
                        <FaRegHandPointRight style={{ color: "#1487C6" }} />{" "}
                        Random Foam for general packing purpose.
                      </li>

                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          2. Foam used to different packing purpose like box
                          packing, individual item packing etc.,
                        </b>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <b>
                          {" "}
                          3. Foam can be customized as per the requirements.
                        </b>
                      </li>
                    </ul>
                  </p>

                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button
                        className="btn btn-primary viewAllProducts"
                        onClick={handleShow1}
                      >
                        Ask For Price
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div id="Pacaking Covers"
                className="row products"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="col-md-12 text-center">
                  <h3 style={{ color: "#1487C6" }}>PACKING COVERS</h3>
                </div>
                <div className="col-md-6 p-3">
                  <img
                    src="products-9.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "300px",
                      border: "2px solid #1487C6",
                    }}
                  />
                </div>
                <div className="col-md-6 p-3">
                  <img
                    src="products-10.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "300px",
                      border: "2px solid #1487C6",
                    }}
                  />
                </div>
                <hr className="mt-3 mb-1" />
                <div className="col-md-12 mt-4">
                  <h4 style={{ color: "#1487C6" }}>ESD Covers</h4>
                  <p style={{ textAlign: "justify" }}>
                    ESD Covers provide an effective method of controlling
                    electrostatic discharge while protecting cart contents from
                    contamination and moisture.
                  </p>
                </div>
                <hr className="mt-3 mb-1" />
                <div className="col-md-12 mt-4">
                  <h4 style={{ color: "#1487C6" }}>PE Covers</h4>
                  <p style={{ textAlign: "justify" }}>
                    Polyethylene (PE) is the plastic film used by the majority
                    of growers because of its affordability, flexibility and
                    easy manufacturing. It comes in a variety of thicknesses,
                    such as a low density form (LDPE) as well as high density
                    form (HDPE).
                  </p>
                </div>
                <hr className="mt-3 mb-1" />
                <div className="col-md-12 mt-4">
                  <h4 style={{ color: "#1487C6" }}>VCI Covers</h4>
                  <p style={{ textAlign: "justify" }}>
                    Volatile corrosion inhibitors (VCI) are a type of corrosion
                    inhibitor that are used to protect ferrous materials and non
                    ferrous metals against corrosion or oxidation where it is
                    impractical to apply surface treatments. They slowly release
                    compounds within a sealed airspace that actively prevents
                    surface corrosion. A typical application is to protect
                    stored tools or parts inside bags, boxes or cupboards, one
                    advantage of VCIs being that if the container is opened and
                    reclosed, levels of inhibitor will recover.
                  </p>
                </div>
                <hr className="mt-3 mb-1" />
                <div className="col-md-12 mt-4">
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        src="/products-10.jpg"
                        alt=""
                        style={{ width: "90%", height: "150px" }}
                      />
                    </div>
                    <div className="col-md-9">
                      <h4 style={{ color: "#1487C6" }}>Paper Bag</h4>
                      <p style={{ textAlign: "justify" }}>
                        Paper bag of different sizes: Wondering whether your
                        business should start using paper bags? While they might
                        not be the most interesting topic in the world,
                        understanding the differences between various types of
                        bags and their capacities and functions can be useful
                        for any restaurant, take-out business, or grocery store.
                      </p>
                    </div>
                  </div>
                </div>
                <hr className="mt-3 mb-1" />
                <div className="col-md-12 mt-4">
                  <h4 style={{ color: "#1487C6" }}>Bubble Covers</h4>
                  <p style={{ textAlign: "justify" }}>
                    The solar cover or bubble cover (or isothermal cover) is
                    used as soon as the swimming pool is put into service in the
                    spring and until the end of autumn for Southern regions.
                    <br />
                    It has a number of advantages: limits evaporation, keeps the
                    water clean, heats and maintains the water temperature, and
                    reduces water consumption, heating and maintenance products.
                    It is ecological and economic.
                  </p>
                </div>
                <div className="col-md-12 text-center">
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
              </div>

              <div id="Tape Solutions"
                className="row products"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="col-md-12 text-center">
                  <h3 style={{ color: "#1487C6" }}>PRINTING SOLUTIONS</h3>
                </div>
                <div className="col-md-3">
                  <img
                    src="products-11.jpg"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9 p-3">
                  <h4 style={{ color: "#1487C6" }}>Labels and Ribbons</h4>
                  <p>
                    We offer plain direct thermal labels, thermal transfer
                    labels and thermal ribbons suitable for all common barcode
                    printers.
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
              </div>

              <div id=""
                className="row products"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="col-md-12 text-center">
                  <h2 style={{ color: "#1487C6" }}>TAPE SOLUTIONS</h2>
                </div>
                <div className="col-md-3">
                  <img
                    src="products-12.jpg"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9">
                  <h3 style={{ color: "#1487C6" }}>
                    Packing tapes- Bopp tape, Printed tapes
                  </h3>
                  <p>
                  <ul style={{padding:"0px", fontSize:"15px",textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight style={{ color: "#1487C6" }} /> Box
                        Sealing Tape, Parcel Tape, Packing Tape.
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Pressure sensitive tape used for closing or sealing of
                        corrugated boxes
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Comes in 48mm (2 inch) & 72mm (3 inch) in 45Mtrs &
                        65Mtrs
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Printed Engineered Tapes for Branding purpose
                      </li>
                    </ul>
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
                <hr className="mt-3 mb-1" />
                <div className="col-md-3">
                  <img
                    src="products-13.jpg"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9">
                  <h3 style={{ color: "#1487C6" }}>
                    Packing Strapping Rolls & Metal Clips
                  </h3>
                  <p>
                  <ul style={{padding:"0px", fontSize:"15px",textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight style={{ color: "#1487C6" }} /> Box
                        Packing Box sealing, Reinforcing wooden boxes, crates
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Material Types - Steel, Nylon, Polypropylene
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Usage Type - Manual, Semi-Automatic & Automatic
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Dimensions - ¾”, 1”, 2” etc.,
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Colour - White, Yellow, Red, Green, Black, Transparent
                      </li>
                    </ul>
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>

                <hr className="mt-3 mb-1" />
                <div className="col-md-3">
                  <img
                    src="products-14.png"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9">
                  <h3 style={{ color: "#1487C6" }}>
                    Packing Shrink Wrapping / Stretch Wrapping Rolls
                  </h3>
                  <p>
                  <ul style={{padding:"0px", fontSize:"15px",textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight style={{ color: "#1487C6" }} /> Box
                        Used in individual packing of items, carton box
                        wrapping, pallet load.
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Polypropylene in different gauges.
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Different lengths are used for different usage.
                      </li>
                    </ul>
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
                <hr className="mt-3 mb-1" />
                <div className="col-md-3">
                  <img
                    src="products-15.png"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9">
                  <h3 style={{ color: "#1487C6" }}>Packing Air Bubble Rolls</h3>
                  <p>
                  <ul style={{padding:"0px", fontSize:"15px",textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight style={{ color: "#1487C6" }} /> Box
                        Offers cushioning and protection against breakage and
                        damage during transit or storage.
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Material Types - Steel, Nylon, Polypropylene
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Resistant to moisture, fungi and corrosive chemicals.
                      </li>
                    </ul>
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>

                <hr className="mt-3 mb-1" />
                <div className="col-md-3">
                  <img
                    src="products-17.jpg"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9">
                  <h3 style={{ color: "#1487C6" }}>Sealing Lead, Wire</h3>
                  <p>
                  <ul style={{padding:"0px", fontSize:"15px",textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight style={{ color: "#1487C6" }} /> Box
                        Sealing wire & Lead are used to seal the carton boxes
                        after packing for security purpose.
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Available in different gauges.
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Used as locks in different applications in different
                        pattern.
                      </li>
                    </ul>
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
                <hr className="mt-3 mb-1" />
                <div className="col-md-3">
                  <img
                    src="products-18.jpg"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9">
                  <h3 style={{ color: "#1487C6" }}>Silica Gel</h3>
                  <p>
                  <ul style={{padding:"0px", fontSize:"15px",textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight style={{ color: "#1487C6" }} /> Box
                        Silica gel comes in granular form in different weights
                        as per the requirements.
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight style={{ color: "#1487C6" }} /> It
                        is used as a catalyst to keep the materials dry & free
                        from moisture.
                      </li>
                    </ul>
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
              </div>

              <div id="Safety Items"
                className="row products"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="col-md-12 text-center">
                  <h2 style={{ color: "#1487C6" }}>SAFETY ITEMS</h2>
                </div>
                <div
                  className="col-md-6 "
                  style={{ borderRight: "1px solid #E3E3E4" }}
                >
                  <div className="text-center">
                    <img
                       className="pimg"
                      src="products-19.jpg "
                      alt=""
                    />
                  </div>
                  <br />
                  <h4 style={{ color: "#1487C6" }}>
                    Personal protective equipments (PPE's)
                  </h4>
                  <p style={{ textAlign: "justify" }}>
                    Personal protective equipment (PPE) refers to protective
                    clothing, helmets, goggles, or other garments or equipment
                    designed to protect the wearer's body from injury or
                    infection. The hazards addressed by protective equipment
                    include physical, electrical, heat, chemicals, biohazards,
                    and airborne particulate matter. Protective equipment may be
                    worn for job-related occupational safety and health
                    purposes, as well as for sports and other recreational
                    activities. "Protective clothing" is applied to traditional
                    categories of clothing, and "protective gear" applies to
                    items such as pads, guards, shields, or masks, and others.
                  </p>

                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button
                        className="btn btn-primary viewAllProducts mb-2"
                        onClick={handleShow1}
                      >
                        Ask For Price
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="text-center">
                    <img
                       className="pimg"
                      src="safety.jpg "
                      alt=""
                    />
                  </div>
                  <br />
                  <h4 style={{ color: "#1487C6" }}>Safety Solutions</h4>
                  <p style={{ textAlign: "justify" }}>
                    Safety Solutions can assist with hazard identification, risk
                    analysis and safety managements.
                  </p>

                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button
                        className="btn btn-primary viewAllProducts"
                        onClick={handleShow1}
                      >
                        Ask For Price
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div id="Industrial Spares & Machinary Sapares"
                className="row products"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="col-md-4">
                  <img
                    src="gloves.jpg"
                    alt=""
                    style={{ width: "100%", height: "449px" }}
                  />
                </div>
                <div className="col-md-8">
                  <h3 style={{ color: "#1487C6" }}>Cotton Wastes and Gloves</h3>
                  <p>
                  <ul style={{padding:"0px", fontSize:"15px",textAlign:"justify"}}>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight style={{ color: "#1487C6" }} /> Box
                        Available in both Woven & Non-Woven fabrics.
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Available in Cut pieces, Roll form, short length.
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Cotton Gloves available in sizes- 8”, 8.5”, 9”.
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Dimensions - ¾”, 1”, 2” etc.,
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {" "}
                        <FaRegHandPointRight
                          style={{ color: "#1487C6" }}
                        />{" "}
                        Colour - White, Yellow, Red, Green, Black, Transparent
                      </li>
                    </ul>
                  </p>
                  <h3 style={{ color: "#1487C6" }}>Acetone</h3>
                  <p style={{ textAlign: "justify" }}>
                    Acetone, a colorless liquid, is a solvent used in
                    manufacture of plastics and other industrial products. It
                    may also be used to a limited extent in household products,
                    including cosmetics and personal care products, where its
                    most frequent application would be in the formulation of
                    nail polish removers. It is widely used in the textile
                    industry for degreasing wool and degumming silk.
                  </p>
                  <h3 style={{ color: "#1487C6" }}>Isopropyl Alcohol (IPA)</h3>
                  <p style={{ textAlign: "justify" }}>
                    Isopropyl Alcohol cleaner is an excellent for tape head
                    cleaning, optics and fibre optics, CDs, DVDs, etc.
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
              </div>

              <div id="Services"
                className="row products"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="col-md-3">
                  <img
                    src="spares.jpg"
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </div>
                <div className="col-md-9 xfgfd">
                  <h3 style={{ color: "#1487C6" }}>
                    INDUSTRIAL SPARES & MACHINERY SPARES
                  </h3>
                  <p>
                    <FaRegHandPointRight style={{ color: "#1487C6" }} /> We
                    provide all kinds of industrial and machinery spares like
                    fasteners, motors, machinery spares, electrical spares,
                    metal cabinets, pulleys, belts.
                  </p>
                  <button
                    className="btn btn-primary viewAllProducts"
                    onClick={handleShow1}
                  >
                    Ask For Price
                  </button>
                </div>
              </div>

              <div 
                className="row products1"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div
                  className="col-md-3"
                  style={{ backgroundColor: "#1487C6" }}
                >
                  <h2 style={{ color: "white", marginTop: "4rem" }}>
                    SERVICES
                  </h2>
                </div>
                <div className="col-md-9 xfgfd" >
                  <p>
                    <FaRegHandPointRight style={{ color: "#1487C6" }} />
                    Cost Effective One stop Solution for your MRO Products
                  </p>
                  <p>
                    <FaRegHandPointRight style={{ color: "#1487C6" }} />
                    Sourcing Solutions
                  </p>
                  <p>
                    <FaRegHandPointRight style={{ color: "#1487C6" }} />
                    Man Power Solutions in SCM Domain
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tell-us-display">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
              <img
                src="enquire-img.jpg"
                alt=""
                style={{ width: "100%", height: "300px" }}
              />
            </div>
            <div className="col-md-8" data-aos="fade-up" data-aos-duration="3000">
              <h2>Tell Us What You Are Looking For?</h2>
              <Form action="" ref={form} onSubmit={sendEmail} >
                <div className="inputs-row">
            
                 <div className="col-6 mb-2">
                 <input className="text"
                  type="text"
                  name="user_phone"
                  maxLength={10}
                  placeholder="Enter Your Mobile"
                  required
                />
                </div>
                </div>
               

                <textarea
                  id="subject"
                  name="user_message"
                  placeholder="Enter Your Product / Service Name"
                  style={{
                    height: "111px",
                    margin: "6px 0 16px 0",
                    padding: "10px",
                  }}
                  required
                ></textarea>
                 <div className="row">
                <div
                  className="col-md-12 mt-2"
                  style={{ marginBottom: "2rem" }}
                >
                  <button
                  type="submit"
                  value="Send"
                    className="btn btn-primary viewAllProducts"
                    style={{ width: "5rem" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              </Form>
             
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={show1}
        onHide={handleClose1}
        style={{ zIndex: "99999", marginTop: "55px" }}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#1487C6", borderRadius: "unset" }}
        >
          <Modal.Title style={{ color: "white" }}>Ask For Price</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form ref={form}  onSubmit={sendEmail}>
          <Row>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <FaFolderOpen style={{ color: "#1487C6" }} />
              </InputGroup.Text>
              <Form.Select aria-label="Default select example" name="user_service" required>
                <option >Select</option>
                <option value="Packaging tapes-Bopp tape,clear tape & Printed tapes">
                  Packaging tapes-Bopp tape,clear tape & Printed tapes
                </option>
                <option value="strapping rolls & metal clips">
                  strapping rolls & metal clips
                </option>
                <option value="packing shrings and wrapping rolls">
                  packing shrings and wrapping rolls
                </option>
                <option value="packing air bubble rolls">
                  packing air bubble rolls
                </option>
                <option value="sealinf lead, wire">sealinf lead, wire</option>
                <option value="silica gel">silica gel</option>
                <option value="cotton waste">cotton waste</option>
                <option value="angle boards">angle boards</option>
                <option value="packing pu foams">packing pu foams</option>
                <option value="corrugated packing boxes">
                  corrugated packing boxes
                </option>
                <option value="corrugated master carbon boxes">
                  corrugated master carbon boxes
                </option>
                <option value="pallets for packing">pallets for packing</option>
                <option value="packing solutions">packing solutions</option>
                <option value="packing materials">packing materials</option>
                <option value="packing items">packing items</option>
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <MdPerson style={{ color: "#1487C6" }} />
              </InputGroup.Text>
              <Form.Control
              name="user_name"
                placeholder="Username"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <FaPhoneFlip style={{ color: "#1487C6" }} />
              </InputGroup.Text>
              <Form.Control
              name="user_phone"
                placeholder="Phone no"
                aria-label="Phone no"
                aria-describedby="basic-addon1"
                required
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiTwotoneMail style={{ color: "#1487C6" }} />
              </InputGroup.Text>
              <Form.Control
              name="user_email"
                placeholder="Email"
                aria-label="Email"
                aria-describedby="basic-addon1"
                required
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text style={{ color: "#1487C6" }}>
                Your message here
              </InputGroup.Text>
              <Form.Control as="textarea" aria-label="With textarea" name="user_message" required/>
            </InputGroup>
         <div className="justify-content-center mt-3 d-flex gap-3">
         <Button
        
        style={{
          borderRadius: "unset",
        }}
        variant="secondary"
        onClick={handleClose1}
      >
        Close
      </Button>
         <Button
              type="submit"
              value="Send"
            style={{
              
              backgroundColor: "#1487C6",
              color: "#ffff",
              borderRadius: "unset",
              border: "1px solid #1487C6",
            }}
            variant="danger"
          >
            Send{" "}
            <span>
              {" "}
              <SiMinutemailer />
            </span>
          </Button>
         </div>
          </Row>
          </Form>
        </Modal.Body>
       
      </Modal>
    </div>
  );
};

export default Product_Services;
