import React, { useEffect, useRef } from "react";
import "../Components/Styles/about.css";
import Aos from "aos";
import emailjs from "@emailjs/browser";
import { Col, Form, Row } from "react-bootstrap";

const AboutUs = () => {
  const form = useRef();
  const sendEmail = (e) => {
   

    e.preventDefault();
    emailjs
      .sendForm(
        "service_3w3g9h7",
        "template_oi0qp23",
        form.current,
        "MUIWT1wj9Eqv9fJC0"
      )
      .then(
        (result) => {
          console.log("Inquiry sent successfully:", result.text);
          window.location.assign("/about");
        },
        (error) => {
          console.error("Error sending inquiry:", error.text);
        }
      );
  };

  useEffect(()=>{
    Aos.init();
    window.scroll(0,0);
  })
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <img
            className="aboutimg"
              src="about-img-1.jpg"
              alt=""
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="3000"
            />
          </div>
        </div>
      </div>

      <div className="about-container">
        <div className="container">
        <div className="row mt-2">

          {/* <div className="row text-center pb-2" data-aos="fade-up" data-aos-duration="3000">
            <h2 className="text-typer-animation" style={{ color: "#1487C6" }}>
              ABOUT OUR COMPANY
            </h2>
          </div> */}
           <div className="row text-center mb-2" data-aos="fade-up" data-aos-duration="2000">
            <h2 className="text-typer-animation" style={{ color: "#1487C6" }}>
            ABOUT OUR COMPANY
            </h2>
          </div>
          <div className="service-display">
            <div className="col-md-12" data-aos="fade-up" data-aos-duration="3000">
              <p className="about-des">
                Unique Industrial Solutions (UIS) is a undisputed leader in
                Packaging Solutions be it is domestic or export with industry
                experience of 10+years in Packaging Industry.
                <br />
                UIS has been recognized as the leading business partner for our
                customers in fulfilling end to end solutions in "Packing
                Solutions" below our expertise areas :
              </p>
            </div>
          </div>
          <div className="row">
          <div className="col-md-9" data-aos="fade-up" data-aos-duration="3000">
              <p>
                <ul className="aboutus">
                  <li>
                    <span style={{ fontWeight: "bold", color: "#1487C6" }}>
                      Packing Boxes :{" "}
                    </span>
                    Packing Boxes, All types of Pallets, Packing Consumables,
                    Angle Boards, PP Corrugated Boxes etc.,
                  </li>
                  <br />
                  <li>
                    <span style={{ fontWeight: "bold", color: "#1487C6" }}>
                      Packing Covers :{" "}
                    </span>
                    ESD Covers, PE Covers, VCI Covers, Bubble Covers, HDPE and
                    LDPE Covers etc.,
                  </li>
                  <br />
                  <li>
                    <span style={{ fontWeight: "bold", color: "#1487C6" }}>
                      Packing Foams :{" "}
                    </span>
                    PE Foam, PU Foam, XL-PE Foams as per the customer
                    requirements.
                  </li>
                  <br />
                  <li>
                    <span style={{ fontWeight: "bold", color: "#1487C6" }}>
                      Printing Solutions :{" "}
                    </span>
                    Labels/Ribbons- Thermal, Heat resistant etc.,
                  </li>
                  <br />
                  <li>
                    <span style={{ fontWeight: "bold", color: "#1487C6" }}>
                      Tapes Solutions :{" "}
                    </span>
                    Filament Tapes, Masking Tapes, Kapton Tape, Double Sided
                    Tape, ESD Conductive Grid Tape.
                  </li>
                  <br />
                  <li>
                    <span style={{ fontWeight: "bold", color: "#1487C6" }}>
                      Industrial Consumables :{" "}
                    </span>
                    Gloves, IPA, Acetone, Cotton Wastes, Industrial Oils.
                  </li>
                  <br />
                  <li>
                    <span style={{ fontWeight: "bold", color: "#1487C6" }}>
                      Industrial Spares and Machinery Spares :{" "}
                    </span>
                    Fasteners, Motors, Machinery Spares, Electrical Spares,
                    Metal cabinets, Pulleys, Belts.
                  </li>
                </ul>
              </p>
            </div>

            <div className="col-md-3" data-aos="zoom-in" data-aos-duration="3000">
              <img
                src="about-img-2.png"
                alt=""
                style={{
                  width: "100%",
                  height: "200px",
                  border: "2px solid #1487C6",
                }}
              />
              <br />
              <br />
              <img
                src="about-img-3.jpg"
                alt=""
                style={{
                  width: "100%",
                  height: "200px",
                  border: "2px solid #1487C6",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6" data-aos="fade-up" data-aos-duration="3000">
            <div className="contact-form-section">
              <div className="form-container">
                <div className="faq">
                  <ul className="accordian" style={{ padding: "0px" }}>
                    <li>
                      <input type="radio" name="accordian" id="first" />
                      <label htmlFor="first">Our Strength & Capabilities</label>
                      <div className="content">
                        <p style={{color:"#1487C6"}}>
                          With our expertise & experience, we are capable of
                          providing a total packaging solution that caters
                          specifically to individual requirement.
                        </p>
                      </div>
                    </li>

                    <li>
                      <input type="radio" name="accordian" id="second" />
                      <label htmlFor="second">Our Peace of Mind</label>
                      <div className="content">
                        <p>
                          We ensure that all outgoing goods are certified &
                          accompanied by our own reports and certificates of
                          quality assurance when required. Customers are assured
                          of Traceability, Accountability & Reliability. <br />
                          UIS is dedicated to below few policies of our company:
                          <ul>
                            <li> Quality of product</li>
                            <li>Success of our customer</li>
                            <li> Customer satisfaction</li>
                          </ul>
                        </p>
                      </div>
                    </li>

                    <li>
                      <input type="radio" name="accordian" id="third" />
                      <label htmlFor="third">Our Manufacturing Units</label>
                      <div className="content">
                        <p>
                          We have two manufacturing units for "Packing
                          Solutions" as below to cater the customer base in and
                          around Bangalore. <br />
                          <ul>
                            <li> Plant1 - Hulimavu, South Bangalore.</li>
                            <li> Plant2 - Jalahalli, North Bangalore.</li>
                          </ul>
                        </p>
                      </div>
                    </li>
                    <li>
                      <input type="radio" name="accordian" id="fourth" />
                      <label htmlFor="fourth">Our Production Capacity</label>
                      <div className="content">
                        <p>
                          Current capacity 600 tons / per month. Maximum
                          capacity 1590 tons / per month.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6" data-aos="fade-up" data-aos-duration="3000">
            <div className="contact-form-section">
              <div className="form-container">
                <div className="faq">
                  <ul className="accordian" style={{ padding: "0px" }}>
                    <li>
                      <input type="radio" name="accordian" id="fifth" />
                      <label htmlFor="fifth">Our Quality Policy</label>
                      <div className="content">
                        <p>
                          Achieve customer satisfaction & excellence through
                          continuous improvements of the quality management
                          system.
                        </p>
                      </div>
                    </li>

                    <li>
                      <input type="radio" name="accordian" id="sixth" />
                      <label htmlFor="sixth">Our Quality Objectives</label>
                      <div className="content">
                        <ul>
                          <li>
                            To minimise the customer complaints to 1% from
                            current. On time delivery as per customer
                            requirement.
                          </li>
                          <li>
                            With our steady growth and commitment over the
                            years, we pledge to serve our customers in any
                            industry with excellent services and superior
                            products.
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <input type="radio" name="accordian" id="seventh" />
                      <label htmlFor="seventh">Our Major Customers</label>
                      <div className="content">
                        <p>
                          Aerospace Industries
                          <br />
                          Electronics Industries
                          <br />
                          Automotive Industries
                          <br />
                        </p>
                      </div>
                    </li>
                    <li>
                      <input type="radio" name="accordian" id="eighth" />
                      <label htmlFor="eighth">Our Company Management</label>
                      <div className="content">
                        <p>
                          Mr. Rohith Pranav
                          <br />
                          Mr. Vijay Kumar
                          <br />
                          For Unique Industrial Solutions
                          <br />
                          Rohith Pranav
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tell-us-display">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-2" data-aos="zoom-in" data-aos-duration="3000">
              <img
                src="enquire-img.jpg"
                alt=""
                style={{ width: "100%", height: "300px" }}
              />
            </div>
            <div className="col-md-8" data-aos="fade-up" data-aos-duration="3000">
              <h2>Tell Us What You Are Looking For?</h2>
              <Form action="" ref={form} onSubmit={sendEmail} >
                <div className="inputs-row">
            
                 <div className="col-6 mb-2">
                 <input className="text"
                  type="text"
                  name="user_name"
                  maxLength={10}
                  placeholder="Enter Your Name"
                  required
                />
                 </div>
             
                 <div className="col-6 mb-2">
                 <input className="text"
                  type="text"
                  name="user_phone"
                  maxLength={10}
                  placeholder="Enter Your Mobile"
                  required
                />
                </div>
                </div>
               

                <textarea
                  id="subject"
                  name="user_message"
                  placeholder="Enter Your message here"
                  style={{
                    height: "111px",
                    margin: "6px 0 16px 0",
                    padding: "10px",
                  }}
                  required
                ></textarea>
                 <div className="row">
                <div
                  className="col-md-12 mt-2"
                  style={{ marginBottom: "2rem" }}
                >
                  <button
                  type="submit"
                  value="Send"
                    className="btn btn-primary viewAllProducts"
                    style={{ width: "5rem" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              </Form>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
